import produce from "immer"
import initialState from "../../initial-state"
import move from "lodash-move"

const editorReducer = (state = initialState.editor, action) =>
  produce(state, draft => {
    const getComponentIndex = componentId =>
      state.components.findIndex(({ data }) => data.componentId === componentId)

    switch (action.type) {
      case "REGISTER_COMPONENT_IN_EDITOR": {
        draft.components = [...state.components, action.payload]
        break
      }
      case "SET_EDITOR_DISABLE": {
        draft.disabled = action.payload.disabled
        break
      }
      case "REMOVE_COMPONENT": {
        const { componentId } = action.payload
        const cIndex = getComponentIndex(componentId)

        if (cIndex >= 0) {
          draft.components.splice(cIndex, 1)
          draft.componentMethods.splice(cIndex, 1)
        }

        break
      }
      case "UPDATE_COMPONENT_DATA": {
        const { componentId, data } = action.payload

        if (componentId) {
          const index = getComponentIndex(componentId)
          draft.components[index].data = {
            ...draft.components[index].data,
            ...data,
          }
        }
        break
      }
      case "UPDATE_COMPONENT_METHODS": {
        const { componentId, methods } = action.payload
        const index = getComponentIndex(componentId)
        draft.componentMethods[index] = {
          ...(draft.componentMethods[index] || {}),
          ...methods,
        }
        break
      }
      case "CHANGE_COMPONENT_ORDER": {
        const { index, toIndex } = action.payload
        const components = state.components

        //The +2 is because we have the header and social image
        if (components[index + 2] && components[toIndex + 2]) {
          draft.components = move(draft.components, index + 2, toIndex + 2)
          draft.componentMethods = move(
            draft.componentMethods,
            index + 2,
            toIndex + 2
          )
        }
        break
      }
      case "SET_POST": {
        draft.post = action.payload.post
        break
      }
      case "CLEAR_COMPONENTS": {
        draft.components = initialState.editor.components
        break
      }
      case "SET_EDITOR_CONTENT_TYPE": {
        draft.contentType = action.payload.contentType
        break
      }
      default:
        return state
    }
  })

export default editorReducer
