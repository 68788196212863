import API from "../client"

export async function loginUser(username, password) {
  return await API.auth(username, password)
}

export async function getUserPosts(userId) {
  try {
    return await API.client.get('posts',{author: userId})
  } catch (e) {
    return []
  }
}

export async function setAuthClient(token) {
  return API.authClient(token);
}

export async function registerUser({ recaptchaValue, displayName, email, paypalEmail, username, password }) {
  try {
    return await API.client.create('user',{
      username,
      password,
      paypalEmail,
      email,
      displayName,
      recaptchaValue
    })
  } catch (e) {
    return { ...e, fail: true }
  }
}