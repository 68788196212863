import { post } from "axios"

const StrapiClient = require("strapi-client")

const endpoint = "https://neverdoingthatagain.work"
// const endpoint = "http://localhost:1337"
const strapi = new StrapiClient(endpoint)

export default {
  get client() {
    return strapi
  },
  async authClient(token) {
    return await strapi.currentUser(token)
  },
  async auth(username, password) {
    try {
      const { jwt, user } = await strapi.login(username, password)
      return { jwt, user }
    } catch (e) {}
    return false
  },
}
