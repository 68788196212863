export const setModal = ({ content, header, onConfirm, onCancel, confirmText, cancelText } = {}) =>
  dispatch => {
    return dispatch({
      type: "SET_MODAL",
      payload: { modal: { modalContent: content, header, onConfirm, onCancel, confirmText, cancelText } }
    })
  }


export const clearModal = () => ({ type: "CLEAR_MODAL" })
