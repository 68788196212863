import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Button } from "antd"
import { clearModal } from "../../store/actions/global"

const { confirm } = Modal

const ModalWindow = () => {
  const dispatch = useDispatch();
  const { type, header, modalContent, onConfirm, onCancel, confirmText, cancelText } = useSelector(state => state.global.modal)

  const _onConfirm = async () => {
    onConfirm && await onConfirm();
    dispatch(clearModal());
  };

  const _onCancel = async () => {
    onCancel && await onCancel();
    dispatch(clearModal())
  }

  return (
    <Modal
      title={header}
      visible={!!modalContent}
      onOk={_onConfirm}
      onCancel={_onCancel}
      okText={confirmText}
      cancelText={cancelText}
      type={type}
    >
      <p>{modalContent}</p>
    </Modal>
  )

}

export default ModalWindow