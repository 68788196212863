import produce from "immer"
import each from "lodash/each"
import initialState from "../../initial-state"
import unionBy from "lodash/unionBy"
import union from "lodash/union"

const dashboardReducer = (state = initialState.user, action) => produce(state, draft => {
  switch (action.type) {
    case "SET_USER": {
      each(action.payload.user, (value, key) => {
        draft[key] = value
      })

      break
    }
    case "SET_USER_POSTS": {
      const append = !!action.payload.append
      draft.posts = append ? unionBy(state.posts, action.payload.posts, "id") : action.payload.posts
      break
    }
    case "LOGOUT_USER": {
      return initialState.user
    }
    default:
      return state
  }
})

export default dashboardReducer

