import product from 'immer';
import _ from 'lodash';
import initialState from '../../initial-state';

const globalReducer = (state = initialState.global, action) => product(state, draft => {
    switch (action.type) {
        case 'SET_MODAL': {
            draft.modal = {...initialState.global.modal, ..._.pickBy(action.payload.modal, _.identity)};
            break;
        }
        case 'CLEAR_MODAL': {
            draft.modal = initialState.global.modal;
            break;
        }
        default:
            return state;
    }
});

export default globalReducer;
