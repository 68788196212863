import * as user from './user';
import * as media from './media';
import * as posts from './posts';
import * as API from './client';

export default {
  user,
  media,
  posts,
  API,
}