import { global } from "./global"
import { user } from "./user"
import { header } from "./header"
import { editor } from "./editor"

export default {
  global,
  header,
  user,
  editor
}
