import React from "react"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistReducer,createTransform, persistStore } from "redux-persist"
import expireReducer from "redux-persist-expire"
import { user as userInitialState } from "./initial-state/user"
import { applyMiddleware, combineReducers, createStore } from "redux"
import storage from "redux-persist/lib/storage"

import userReducer from "./reducers/user"
import globalReducer from "./reducers/global"
import editorReducer from "./reducers/editor"
import headerReducer from "./reducers/header"
import services from "../services"
import ModalWindow from "../components/modal"

const editorPersistConfig = {
  key: "editor",
  storage,
  blacklist: ["componentMethods"],
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["header", "editor", "global"]
}

const rootReducer = combineReducers({
  global: globalReducer,
  editor: persistReducer(editorPersistConfig, editorReducer),
  header: headerReducer,
  user: userReducer
})

let store

export default ({ element }) => {

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const composeEnhancers = composeWithDevTools({ trace: true })

  store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(services))
  ))

  const persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ModalWindow/>
        {element}
      </PersistGate>
    </Provider>)
}

export const getStore = () => store