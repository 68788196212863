import produce from "immer"
import initialState from "../../initial-state"

const dashboardReducer = (state = initialState.header, action) => produce(state, draft => {
  switch (action.type) {
    case "SET_HEADER_INLINE_CONTENT": {

      draft.inlineContent.content = action.payload.content

      break
    }
    case "SET_HEADER_BOTTOM_CONTENT": {

      draft.bottomContent.content = action.payload.content

      break
    }
    case "CLEAR_HEADER": {

      draft = initialState.header

      break
    }
    case "SET_HEADER_OPTIONS": {

      draft.options = action.payload.options

      break
    }
    default:
      return state
  }
})

export default dashboardReducer

