import API from "../client"

export async function createPost({ content, title, socialPhoto }) {
  try {
    return await API.client.create("posts", {
      content,
      title,
      socialPhoto,
    })
  } catch (e) {
    return { ...e, fail: true }
  }
}

export async function updatePost(id, { content, title, socialPhoto }) {
  try {
    return await API.client.update("posts", id, {
      content,
      title,
      socialPhoto,
      status: "pending",
    })
  } catch (e) {
    return { ...e, fail: true }
  }
}

export async function getPostById(userId, id) {
  try {
    return await API.client.get("posts", { author: userId, id })
  } catch (e) {
    return { ...e, fail: true }
  }
}
