import API from "../client"

export async function uploadFile(fileObj, options) {
  try {
    const formData = new FormData()
    formData.append("files", fileObj)

    return await API.client.create("upload", formData, options)
  } catch (e) {
    return { ...e, fail: true }
  }
}
